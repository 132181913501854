import type { SVGProps } from 'react';
const SvgMinus = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M17.031 11.531H6.97a.216.216 0 0 0-.219.22v.874c0 .137.082.219.219.219h10.06c.11 0 .219-.082.219-.219v-.875c0-.11-.11-.219-.219-.219"
    />
  </svg>
);
export default SvgMinus;
