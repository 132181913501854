import Image from 'next/image';
import vinylAboutImage from 'public/images/christmas2024/vinyl-about.avif';
import useTranslate from 'src/hooks/use-translate';
import { cloudinaryLoader } from 'src/utilities/image-sdk';

const Illustration = ({ className }: { className: string }) => (
  <Image
    src={vinylAboutImage}
    width={341}
    height={341}
    alt=""
    unoptimized
    loader={cloudinaryLoader}
    className={className}
  />
);

/**
 * Section explaining the Christmas 2024 campaign gift
 */
export function GiftCardsAboutVinyl() {
  const t = useTranslate();

  return (
    <div className="-mx-5 flex max-w-[65rem] flex-col gap-24 border-y border-surface-600 p-6 md:-mx-8 lg:mx-auto lg:flex-row lg:rounded-3xl lg:border lg:p-16">
      <div className="flex flex-col gap-8">
        <h2 className="flex flex-col gap-4 text-balance">
          <span className="block text-campaignAccent typo-subheadline">{t('gift_cards__about_title_line_1')}</span>
          <span className="block typo-title-1">{t('gift_cards__about_title_line_2')}</span>
        </h2>
        <Illustration className="mx-auto lg:hidden" />
        <p className="text-textSecondary typo-body-1">{t('gift_cards__about_description_para_1')}</p>
        <p className="text-textSecondary typo-body-1">{t('gift_cards__about_description_para_2')}</p>
      </div>
      <Illustration className="hidden object-contain lg:block" />
    </div>
  );
}
