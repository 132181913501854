import { useState } from 'react';
import { Transition } from '@headlessui/react';
import MinusIcon from '@stageplus/icons/react/minus';
import PlusIcon from '@stageplus/icons/react/plus';
import clsx from 'clsx';
import { FAQPageJsonLd } from 'next-seo';
import { Faq } from 'src/utilities/get-faqs';

type FaqsProps = {
  faqs: Faq[];
};

type FaqItemProps = {
  faq: Faq;
  onClick: (faqId: number) => void;
  open: boolean;
};

/**
 * Renders an individual FAQ item
 * When clicked, the item will expand or collapse
 */
function FaqItem({ faq, onClick, open }: FaqItemProps) {
  const { id, question, answer } = faq;

  function handleClick() {
    onClick(id);
  }

  return (
    <div className="border-b border-y-divider light:border-y-divider" data-test="faq-item">
      <button
        onClick={handleClick}
        className={clsx(
          'block w-full text-left text-textPrimary opacity-100 outline-offset-1 transition-opacity focus-visible:focus-outline',
          !open && 'hover:opacity-70',
        )}
      >
        <h3 className="dg-text-regular-4 flex items-center py-4">
          <span className="flex-1">{question}</span>
          {open ? <MinusIcon /> : <PlusIcon />}
        </h3>
      </button>
      <Transition
        show={open}
        enter="transition duration-500 ease-out"
        enterFrom="transform opacity-0"
        enterTo="transform opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform opacity-100"
        leaveTo="transform opacity-0"
      >
        <div
          className="dg-text-regular-6 mb-4 text-textPrimary"
          data-test="faq-item-answer"
          dangerouslySetInnerHTML={{
            __html: answer,
          }}
        />
      </Transition>
    </div>
  );
}

/**
 * Renders a list of FAQ items. The list is divided into two columns on larger screens.
 * When an item is clicked, it will expand or collapse. Only one item can be expanded at a time.
 * Structured data is automatically generated for the FAQ items and inserted into the `<head>` tag of the page for SEO purposes.
 */
export function FaqList({ faqs }: FaqsProps) {
  const [activeFaqId, setActiveFaqId] = useState<number>();
  /**
   * Divide the faqs into two arrays of equal length
   * Using a standard grid layout would cause faq items in the other column to
   * be pushed down when an item is expanded.
   */
  const leftColumnItems = faqs.slice(0, Math.ceil(faqs.length / 2));
  const rightColumnItems = faqs.slice(Math.ceil(faqs.length / 2));

  /**
   * When a FAQ item is clicked, we want to toggle its active state
   * If the item is already active, we want to close it
   */
  function handleFaqItemClick(faqId: number) {
    setActiveFaqId((currentFaqId) => (faqId === currentFaqId ? undefined : faqId));
  }

  if (faqs.length === 0) return null;

  return (
    <>
      {/* Structured data for the FAQ items */}
      <FAQPageJsonLd
        mainEntity={faqs.map(({ question, answer }) => ({
          questionName: question,
          acceptedAnswerText: answer,
        }))}
      />
      {/* FAQ items */}
      <div
        className="border-t border-divider light:border-divider md:grid md:grid-cols-2 md:gap-x-6 md:border-t-0 xl:gap-x-8"
        data-test="faq-list"
      >
        {[leftColumnItems, rightColumnItems].map((faqs, index) => (
          <div key={index} className="border-y-divider light:border-divider md:border-t">
            {faqs.map((faq) => (
              <FaqItem key={faq.id} faq={faq} open={faq.id === activeFaqId} onClick={handleFaqItemClick} />
            ))}
          </div>
        ))}
      </div>
    </>
  );
}
