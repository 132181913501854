import type { SVGProps } from 'react';
const SvgPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M17.031 11.531h-4.375V7.156c0-.109-.11-.218-.219-.218h-.875a.216.216 0 0 0-.218.218v4.375H6.969a.216.216 0 0 0-.219.22v.874c0 .137.082.219.219.219h4.375v4.375c0 .137.082.219.218.219h.875c.11 0 .22-.082.22-.22v-4.374h4.374c.11 0 .219-.082.219-.219v-.875c0-.11-.11-.219-.219-.219"
    />
  </svg>
);
export default SvgPlus;
